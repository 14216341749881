import axios from "axios";
let host=location.host
var config={}
if(host=='vendor.wndw.in'){
   config = {
    withCredentials: true,
    baseURL: "https://admin.wndw.in/api"
    // baseURL: API.baseURL testing
  };
}
else{
    config = {
    withCredentials: true,
     baseURL: "https://lhmadmin.greenhonchos.com/api"
    // baseURL: API.baseURL testing
  };
}
let instance = axios.create(config);

instance.interceptors.response.use(
  function(res) {
    return res.data;
  },
  function(error) {
    let message = "Something went wrong";
    // var status = "failure";
    try {
      message = error.response.data.message;
    //   status = error.response.data.status;
    } catch (e) {
        return e
    }
    throw { message };
  }
);
export default instance;
