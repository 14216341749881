<template>
    <nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white"  id="sidenav-main">
        <div class="container-fluid" >
            <!--Toggler-->
            <navbar-toggle-button @click.native="showSidebar">
                <span class="navbar-toggler-icon"></span>
            </navbar-toggle-button>
            <div class="row pt-3">
            <div class="col-8"><router-link class="navbar-brand pt-0 "  :to="{ name: 'dashboard', query: 1 }">
              <span >Dashboard</span>
            </router-link></div>
            <div class="col-4">
              <i class="fas fa-times-circle"  v-show="isMobile== false" @click="closeSidebarD" style='font-size:26px;float: right'></i>
            </div>
            </div>
            <slot name="mobile-right">
                <ul class="nav align-items-center d-md-none">
                    <base-dropdown class="nav-item" position="right">
                        <a slot="title" class="nav-link nav-link-icon" href="#" role="button" data-toggle="dropdown"
                           aria-haspopup="true" aria-expanded="false">
                            <i class="ni ni-bell-55"></i>
                        </a>
                        <a class="dropdown-item" href="#">Action</a>
                        <a class="dropdown-item" href="#">Another action</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#">Something else here</a>
                    </base-dropdown>
                    <base-dropdown class="nav-item" position="right">
                        <a slot="title" class="nav-link" href="#" role="button">
                            <div class="media align-items-center">
                              <span class="avatar avatar-sm rounded-circle">
                                <img alt="Image placeholder" src="img/worker.png">
                              </span>
                            </div>
                        </a>

                        <div class=" dropdown-header noti-title">
                            <h6 class="text-overflow m-0">Welcome!</h6>
                        </div>
                        <router-link v-if="this.isVendor" to="/profile" class="dropdown-item">
                            <i class="ni ni-single-02"></i>
                            <span>My profile</span>
                        </router-link>
                        <router-link v-if="this.isVendor" to="/profile" class="dropdown-item">
                            <i class="ni ni-settings-gear-65"></i>
                            <span>Settings</span>
                        </router-link>
                        <router-link v-if="this.isVendor" to="/" class="dropdown-item">
                            <i class="ni ni-calendar-grid-58"></i>
                            <span>Activity</span>
                        </router-link>
                        <router-link v-if="this.isVendor" to="/" class="dropdown-item">
                            <i class="ni ni-support-16"></i>
                            <span>Support</span>
                        </router-link>
                        <div class="dropdown-divider"></div>
                        <a href="#!" class="dropdown-item">
                            <i class="ni ni-user-run"></i>
                            <span>Logout</span>
                        </a>
                    </base-dropdown>
                </ul>
            </slot>
            <slot></slot>
            <div v-show="$sidebar.showSidebar" class="navbar-collapse collapse show" id="sidenav-collapse-main">
                <div class="navbar-collapse-header ">
                    <div class="row">
                        <div class="col-6 collapse-brand">
                            <router-link to="/">
                                <!-- <img :src="logo"> -->
                            </router-link>
                        </div>
                        <div class="col-6 collapse-close">
                            <navbar-toggle-button @click.native="closeSidebar"></navbar-toggle-button>
                        </div>
                    </div>
                </div>

                <ul class="navbar-nav ">
                    <slot name="links">
                    </slot>
                </ul>
            </div>
            <div v-if="isMobile == false && !this.isVendor">
              <router-link to="/settings" class="nav-link  left">
                      <i class="ni ni-settings mr-3"></i>
                       <span class="nav-link-text">Settings</span>
                </router-link>
            </div>
            </div>
    </nav>
</template>
<script>
  import NavbarToggleButton from '@/components/NavbarToggleButton'
  import { isMobile } from 'mobile-device-detect';
  export default {
    name: 'sidebar',
    components: {
      NavbarToggleButton
    },
    data(){
      return {
        isVendor: false,
        isMobile: false,
        sidebar: false,
      }
    },
    props: {
      logo: {
        type: String,
        default: 'img/brand/green.png',
        description: 'Sidebar app logo'
      },
      autoClose: {
        type: Boolean,
        default: true,
        description: 'Whether sidebar should autoclose on mobile when clicking an item'
      }
    },
    provide() {
      return {
        autoClose: this.autoClose
      };
    },
    methods: {
     
      closeSidebar() {
        this.sidebar = false;
        this.$root.sidebar =  true;
        this.$sidebar.displaySidebar(false)
      },
       closeSidebarD() {
         console.log("c",this.$root);
         let a  =  document.getElementById("sidenav-main");
         let b  = document.getElementById("mainContent");
         let c = document.getElementById("sidebar-icon");
         a.classList.add("test1")
         b.classList.add("test")
         c.classList.add("test2")
       
      },
      showSidebar() {
        this.$sidebar.displaySidebar(true)
      }
    },
    beforeDestroy() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.showSidebar = false;
      }
    },
    created(){
      if(localStorage.getItem("role") == "vendor"){
        this.isVendor = true;
      }
      if (isMobile) this.isMobile =  true;
    },
  };
</script>
<style >
  .test{
    margin-left:0 !important
  }
  @media only screen and (min-width: 767px) {
    .test1{
    left:-250px !important
  }
  .test2{
    display: block !important
  }
}
  /* .test1{
    left:-250px !important
  }
  .test2{
    display: block !important
  } */
</style>
