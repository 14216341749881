<template>

  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
     <!-- <sidebar-menu :menu="menu" /> -->
    <side-bar
      :background-color="sidebarBackground"
      short-title="
      Dashboard"
      title="Dashboard"
      v-if="invoice == false"
    >
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-tv-2 text-primary',
            path: '/dashboard'
          }"
        />
       
        <sidebar-item :link="{name: 'Orders',id:1, icon: 'ni ni-box-2 ', path: '/orders',
         children:[
         {name:'New Orders',path:'/orders/requests' },
         {name:'Pending Orders', path : '/orders/all', query: 'p' } ,
         {name:'Completed Orders', path : '/orders/all', query:'c' } ,
         {name:'Failed Orders', path : '/orders/all', query: 'f' } ,
         {name:'Customer Pickups', path: '/orders/pick-ups', query: 'cp'}
        ]}"/>
        <sidebar-item v-if="this.isVendor" :link="{name: 'Products',id:2, icon: 'ni ni-tag ', path: '/products',
         children:[
         {name:'Listing', path: '/products'},
        ]}"/>
        <sidebar-item v-if="!this.isVendor" :link="{name: 'Products',id:2, icon: 'ni ni-tag ', path: '/products',
         children:[
         {name:'Listing', path: '/products'},
         {name:'Add New', path: '/addnew'},
         {name:'Inventory Update', path: '/inventory-update'},
        ]}"/>
        <sidebar-item v-if="!this.isVendor" :link="{name: 'Store Closure', id:3, icon: 'fas fa-store', path: '/store' ,
        children:[]}"/>
        <sidebar-item v-if="!this.isVendor" :link="{name: 'Settlements', id:3, icon: 'ni ni-single-02', path: '/settlements' ,
        children:[]}"/>
        <sidebar-item :link="{name: 'Returns', id:4, icon: 'fa fa-undo', path: '/returns' ,
        children:[
        {name:'Pending Request', path : '/returns', query: 'p' } ,
         {name:'Completed Request', path : '/returns', query:'c' } ,
        ]}"/>
        <sidebar-item v-if="!this.isVendor" :link="{name: 'Reviews', id:4, icon: 'fa fa-inbox', path: '/reviews' ,children:[]}"/>
        
        <!-- <sidebar-item :link="{name: 'Customer', icon: 'ni ni-single-02', path: '/customer' ,children:[]}"/> -->
        <!-- <sidebar-item :link="{name: 'Analytics', icon: 'ni ni-chart-bar-32', path: '/products' ,children:[]}"/> -->
        <!-- <sidebar-item :link="{name: 'Discounts', icon: 'ni ni-money-coins', path: '/discounts' ,children:[]}"/> -->
        <sidebar-item v-if="!this.isVendor" :link="{name: 'Storefront',id:4, icon: 'ni ni-shop', path: '/store' , children:[{name:'Media', path : 'media'},{name:'About Page',path:'/about'},{name:'Themes',path:'/themes'}]}"/>
        <!-- <sidebar-item :link="{name: 'Storefront',id:4, icon: 'ni ni-shop', path: '/store' , children:[{name:'Banners',path:'banners'},
        {name:'Media', path : 'media'},{name:'About Page',path:'/about'}]}"/> -->

        <sidebar-item v-if="!this.isVendor" :link="{name: 'Report',id:5, icon: 'ni ni-chart-bar-32', path: '/store' , children:[{name:'Product Review', path : 'ProductReview'},{name:'Sales order',path:'/SaleOrder'},{name:'Return order',path:'/ReturnOrder'},{name:'Inventory',path:'/Inventory'},{name:'Seller review',path:'/SellerReview'}]}"/>
       <sidebar-item  v-if="isMobile==true && !this.isVendor" :link="{name: 'Settings', icon: 'ni ni-settings', path: '/settings' ,children:[]}"/>

      </template>
    </side-bar> 

    <div class="main-content" id="mainContent" :data="sidebarBackground">
      <dashboard-navbar v-if="invoice == false"></dashboard-navbar>
      
      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
  import DashboardNavbar from './DashboardNavbar.vue';
  // import NavbarToggleButton from '@/components/NavbarToggleButton'
  import ContentFooter from './ContentFooter.vue';
  import { FadeTransition } from 'vue2-transitions';
  import { isMobile } from 'mobile-device-detect';
  // import { getMessaging, getToken } from 'firebase/messaging';
  import axios from "../axiosCustom";
  import { messaging } from '../firebase.js'

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      FadeTransition,
      // NavbarToggleButton
    },
    data() {
      return {
        isVendor: false,
        footer_active:'',
        isMobile: false,
        invoice : false,
        sidebarBackground: 'vue', //vue|blue|orange|green|red|primary
         menu: [
                {
                    href: '/',
                    title: 'Dashboard',
                    icon: 'fa fa-user'
                },
                {
                    href: '#',
                    title: 'Charts',
                    icon: 'fa fa-chart-area'
                },
            ]
      };
    },
    methods: {
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      },
      toggle(name){
          if(name != this.footer_active){
              this.footer_active = name;
          }
          else
          {
              this.footer_active = '';
          }
      },
      closeSidebar() {
        this.sidebar = false;
        this.$sidebar.displaySidebar(false)
      },
    },
    async mounted (){
      var token = window.$cookies.get("accessToken");
      if (messaging) {
        messaging.requestPermission()
            .then(async function() {
              const currentToken = await messaging.getToken()
              let params = {
                device_token: currentToken,
              };
              const res = await axios.post("/vendor/fcm-orders", params, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
              console.log(res);
              // Send the token to your server and update the UI if necessary
              // ...
            })
            .catch(function(err) {
              console.log("Unable to get permission to notify.", err);
            });
        navigator.serviceWorker.addEventListener("message", (message) => console.log(message));
      }
      // Notification.requestPermission(result => {
      //   if(result === "granted"){
      //     const messaging = getMessaging();
      //     getToken(messaging, { vapidKey: 'BJ64HXksjDezmH9wgVu0wllaeBZ98PVa_JMN2rgO1NGCMcpT5sUyraknaee25-u6t6hAKoXVQK52R6Xt17WOwEs' }).then(async (currentToken) => {
      //       if (currentToken) {
      //         let params = {
      //           device_token: currentToken,
      //           };
      //         const res = await axios.post("/vendor/fcm-orders", params, {
      //           headers: {
      //             Authorization: `Bearer ${token}`,
      //           },
      //         });
      //         console.log(res);
      //         // Send the token to your server and update the UI if necessary
      //         // ...
      //       } else {
      //         // Show permission request UI
      //         console.log('No registration token available. Request permission to generate one.');
      //         // ...
      //       }
      //     }).catch((err) => {
      //       console.log('An error occurred while retrieving token. ', err);
      //       // ...
      //     });
      //     console.log("Firebase Var", messaging);
      //   }
      // })
      if(localStorage.getItem("role") == "vendor"){
        this.isVendor = true;
      }
      if(this.$route.path == '/invoice' || this.$route.name == 'Slip'){
        this.invoice = true
      }
      if(isMobile) this.isMobile =  true;
    },
  };
</script>
<style lang="scss">
 .navbar-vertical.navbar-expand-md.fixed-left+.main-content{
      margin-left:0;
  }

  .navbar-vertical.navbar-expand-md .navbar-nav .nav-link.active:before {
    border-left: 4px solid #2dcecc !important;
}
.navbar-vertical a.nav-link {
    color:#000 !important;
}
.navbar-vertical .dropbtns {
    color:#000 !important;
}
.navbar-vertical a.active.nav-link.router-link-active {
    font-weight: bold;
}
.navbar-vertical .drop-link-child a {
    margin: .1rem;
}
.navbar-vertical .drop-link-child a:hover {
    background-color: rgba(46,193,205,.1) !important;
    border-radius: 2rem ;
    color: #000 !important;
}
.navbar-vertical .drop-link-child a.active:hover {
    color: #2dcecc !important;
}
.navbar-vertical .drop-link-child a.active {
    background-color: rgba(46,193,205,.1);
    border-radius: 2rem;
    color: #2dcecc;
}

</style>
