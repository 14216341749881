<template>
  <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">
      <tr>
        <slot name="columns" :columns="columns">
          <th>
                <label class="form-checkbox">
                <input type="checkbox" v-model="selectAll" @click="select">
                 <i class="form-icon"></i>
                </label>
          </th>
          <th v-for="item in columnData" :key="item.id">{{item.name}}</th>
          <!-- <th v-for="column in columns" :key="column">{{ column }}</th> -->
        </slot>
      </tr>
    </thead>
    <tbody :class="tbodyClasses">
      <tr v-for="(item, index) in data" :key="index">
        <slot :row="item" :index="index">
          <td
            v-for="(column, index) in colsWithValue(item)"
            :key="index">
            {{ itemValue(item, column) }}
          </td>
        </slot>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  name: 'base-table',
     data() {
      return {
      }
    },
  props: {
    columnData:Array,
    columns: {
      type: Array,
      default: () => [],
      description: 'Table columns'
    },
    data: {
      type: Array,
      default: () => [],
      description: 'Table data'
    },
    type: {
      type: String, // striped | hover
      default: '',
      description: 'Whether table is striped or hover type'
    },
    theadClasses: {
      type: String,
      default: '',
      description: '<thead> css classes'
    },
    tbodyClasses: {
      type: String,
      default: '',
      description: '<tbody> css classes'
    }
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
    colsWithValue() {
      return (row) => {

        return this.columnData.filter(column => this.hasValue(row, column.value))
      }
    }
  },
  methods: {
     select() {
        this.selected = [];
        if (!this.selectAll) {
          for (let i in this.data) {
            console.log(i);
            this.selected.push(this.data[0].id_product);
          }
        }
      },
    hasValue(item, column) {
      // console.log("item", column)
       let a  = item[column] !== 'undefined';
       return a
    },
    itemValue(item, column) {
      console.log("ite",item,column)
      return item[column.value];
    }
  }
};
</script>
<style></style>
