/*!

=========================================================
* Vue Argon Dashboard - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
CI CD Test
*/
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import ArgonDashboard from './plugins/argon-dashboard'
import axios from "./axiosCustom";
import {store} from "./store"
import VueCookies from 'vue-cookies'
import 'v-msgx/dist/msg.min.css';
import Msg from 'v-msgx';
import device from "vue-device-detector";
import JsonCSV from 'vue-json-csv';
import JsonExcel from "vue-json-excel";
import * as serviceWorker from './serviceWorker';

import {Tabs, Tab} from 'vue-tabs-component';

//Firebase configuration

// import { initializeApp } from "firebase/app";
//
// const firebaseConfig = {
//   apiKey: "AIzaSyADVUGqE210lbD399QQASDR5YcESNr_Ktw",
//   authDomain: "personaltest-ced56.firebaseapp.com",
//   projectId: "personaltest-ced56",
//   storageBucket: "personaltest-ced56.appspot.com",
//   messagingSenderId: "748265246327",
//   appId: "1:748265246327:web:0368be5601deda6e9d0072",
//   measurementId: "G-XBNE6W0WL4"
// };
//
// // Initialize Firebase
// initializeApp(firebaseConfig);


Vue.component('downloadCsv', JsonCSV);
Vue.component("downloadExcel", JsonExcel);
Vue.use(device)
Vue.component('tabs', Tabs);
Vue.component('tab', Tab);
Vue.use(Tabs);
Vue.use(VueCookies)
Vue.use(Msg);
Vue.config.productionTip = false
Vue.prototype.$axios = axios;

router.beforeEach((to, from, next) => {

  let isAuth  =  false
  isAuth = (window.$cookies.get("accessToken") !== null)
  // if(to.query == 1){
  //   next({name:'dashboard'})
  //     return
  // }
  if (to.name !== 'login' && !isAuth) next({ name: 'login' })
  
  // if(to.name == 'dashboard' && from.name !== 'dashboard' && isAuth){
  //   console.log("fcghjkl")
    
  //   if(from.name == 'Requests'){
  //     next({name:'Requests'})
  //     return
  //   }
  //   if(from.name == 'Orders'){
  //     next({name:'Orders'})
  //     return
  //   }
  //   if(from.name == 'products'){
  //     next({name:'products'})
  //     return
  //   }
  //   if(from.name == 'settlements'){
  //     next({name:'settlements'})
  //     return
  //   }
  //   if(from.name == 'Banners'){
  //     next({name:'Banners'})
  //     return
  //   }
  //   if(from.name == 'Videos'){
  //     next({name:'Videos'})
  //     return
  //   }
  //   if(from.name == 'About'){
  //     next({name:'About'})
  //     return
  //   }
  //   if(from.name == 'Details'){
  //     next({name:'Details'})
  //     return
  //   }
  //   else{
  //     console.log("wnter in ese")
  //     if(to.name == 'dashboard' && from.name !='dashboard'){
  //       next()
  //       return
  //     }
  //   }
    
  // }
  if (to.name == 'login' && isAuth){
    next({name:'dashboard'})
  }
  else {
  next()
} 
})

if (serviceWorker in navigator) {
  navigator.serviceWorker
      .register("firebase-messaging-sw.js")
}


Vue.use(ArgonDashboard)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
