var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[(_vm.invoice == false)?_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":"\n    Dashboard","title":"Dashboard"}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          icon: 'ni ni-tv-2 text-primary',
          path: '/dashboard'
        }}}),_c('sidebar-item',{attrs:{"link":{name: 'Orders',id:1, icon: 'ni ni-box-2 ', path: '/orders',
       children:[
       {name:'New Orders',path:'/orders/requests' },
       {name:'Pending Orders', path : '/orders/all', query: 'p' } ,
       {name:'Completed Orders', path : '/orders/all', query:'c' } ,
       {name:'Failed Orders', path : '/orders/all', query: 'f' } ,
       {name:'Customer Pickups', path: '/orders/pick-ups', query: 'cp'}
      ]}}}),(this.isVendor)?_c('sidebar-item',{attrs:{"link":{name: 'Products',id:2, icon: 'ni ni-tag ', path: '/products',
       children:[
       {name:'Listing', path: '/products'} ]}}}):_vm._e(),(!this.isVendor)?_c('sidebar-item',{attrs:{"link":{name: 'Products',id:2, icon: 'ni ni-tag ', path: '/products',
       children:[
       {name:'Listing', path: '/products'},
       {name:'Add New', path: '/addnew'},
       {name:'Inventory Update', path: '/inventory-update'} ]}}}):_vm._e(),(!this.isVendor)?_c('sidebar-item',{attrs:{"link":{name: 'Store Closure', id:3, icon: 'fas fa-store', path: '/store' ,
      children:[]}}}):_vm._e(),(!this.isVendor)?_c('sidebar-item',{attrs:{"link":{name: 'Settlements', id:3, icon: 'ni ni-single-02', path: '/settlements' ,
      children:[]}}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{name: 'Returns', id:4, icon: 'fa fa-undo', path: '/returns' ,
      children:[
      {name:'Pending Request', path : '/returns', query: 'p' } ,
       {name:'Completed Request', path : '/returns', query:'c' } ]}}}),(!this.isVendor)?_c('sidebar-item',{attrs:{"link":{name: 'Reviews', id:4, icon: 'fa fa-inbox', path: '/reviews' ,children:[]}}}):_vm._e(),(!this.isVendor)?_c('sidebar-item',{attrs:{"link":{name: 'Storefront',id:4, icon: 'ni ni-shop', path: '/store' , children:[{name:'Media', path : 'media'},{name:'About Page',path:'/about'},{name:'Themes',path:'/themes'}]}}}):_vm._e(),(!this.isVendor)?_c('sidebar-item',{attrs:{"link":{name: 'Report',id:5, icon: 'ni ni-chart-bar-32', path: '/store' , children:[{name:'Product Review', path : 'ProductReview'},{name:'Sales order',path:'/SaleOrder'},{name:'Return order',path:'/ReturnOrder'},{name:'Inventory',path:'/Inventory'},{name:'Seller review',path:'/SellerReview'}]}}}):_vm._e(),(_vm.isMobile==true && !this.isVendor)?_c('sidebar-item',{attrs:{"link":{name: 'Settings', icon: 'ni ni-settings', path: '/settings' ,children:[]}}}):_vm._e()],1)],2):_vm._e(),_c('div',{staticClass:"main-content",attrs:{"id":"mainContent","data":_vm.sidebarBackground}},[(_vm.invoice == false)?_c('dashboard-navbar'):_vm._e(),_c('div',{on:{"click":_vm.toggleSidebar}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }