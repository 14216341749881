<template>
    <base-nav class="navbar-top navbar-dark pl-2"
              id="navbar-main"
              :show-toggle-button="true"
              expand>
              <!-- <div class="ml-2"><i v-for="star in avg_rating" :key="star" class="fa fa-star checked mr-1"></i><i v-if="remainder==true" class="fas fa-star-half checked" ></i></div> -->
              <form v-click-outside="hide" type="button"  @submit="false" class="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
                  <div class="mb-0 serchWrap" >
                      <base-input placeholder="Search"
                                  class="input-group-alternative"
                                  alternative=""
                                  @input="isTyping = true" 
                                  v-model="searchQuery" 
                                  type="text"
                                  @keypress="enterKey($event)"
                                  addon-right-icon="fas fa-search">
                      </base-input>
                      <template v-if="searchDrop">
                          <div class="container-fluid card card-header-search" style="padding: 0 !important;">
                              <card  style="width:100%; float:left" v-if="items.length==0 && products.length == 0">
                                <div slot="header" class="" style="width:100%;">
                                  <div style="float:left">No data found</div>
                                </div>
                              </card>
                              <card v-else style="width:100%; float:left">
                                <div slot="header" class="" style="width:100%;">
                                  <div style="float:left">Store</div>
                                  <div style="float:right">
                                    <div class="form-group">
                                      <select @click="filter" v-model='value'  id="exampleFormControlSelect1">
                                        <option value='all'>Include</option>
                                        <option value='items'>Orders</option>
                                        <option value='products'>Products</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <ul>
                                <li v-for="item in items" :key="item.id" class="row-class mt-2 row">
                                    <div class="col-2">
                                      <img width="45px" height="auto" :src="`https://images.wndw.in/live/inside/100x100/`+item.image.image">
                                    </div>
                                      <div class="col-10" style="cursor:default;">
                                        <span class="item-name">{{item.name}}</span>
                                        <p class="mb-0"><b>(Order-Item)</b></p>
                                      </div>
                                </li>
                                </ul>
                                <ul>
                                  <li v-for="item in products" :key="item.id" class="row-class mt-2 row">
                                      <div class="col-2">
                                      <img width="45px" height="auto" :src="`https://images.wndw.in/live/inside/100x100/`+item.image">
                                      </div>
                                      <div class="item-name col-10">       
                                         <span @click="open(item)">{{item.name}}</span>
                                          <p class="mb-0"><b>available:{{item.quantity}}</b></p>
                                          <span><a class="pointer search-data" target="_blank" :href="item.url_key+'.html'+'?store='+`${item.store}`+'&id='+`${item.id_product}`"><i class="fas fa-external-link-alt m-0"></i></a></span>
                                        </div>
                                          <!-- <p class="mb-0"><b>Description:</b></p> -->
                                      <br>
                                  </li>
                                </ul>
                              </card>
                            </div>
                            
                      </template>
                  </div>
              </form>
              <ul class="navbar-nav align-items-center d-none d-md-flex">
                  <li class="nav-item dropdown">
                      <base-dropdown class="nav-link pr-0">
                          <div class="media align-items-center" slot="title">
                      <span class="avatar avatar-sm rounded-circle">
                        <img alt="Image placeholder" src="/img/worker.png">
                      </span>
                              <div class="media-body ml-2 d-none d-lg-block">
                                  <span class="mb-0 text-sm mr-4 font-weight-bold" style="cursor:pointer">{{userName}}</span>
                              </div>
                          </div>

                          <template>
                              <div class=" dropdown-header noti-title">
                                  <h6 class="text-overflow m-0 ">Welcome!</h6>
                              </div>
                              <router-link v-if="!this.isVendor" to="/profile" class="dropdown-item">
                                  <i class="ni ni-single-02"></i>
                                  <span>My profile</span>
                              </router-link>
                              <router-link v-if="!this.isVendor" to="/settings" class="dropdown-item">
                                  <i class="ni ni-settings-gear-65"></i>
                                  <span>Settings</span>
                              </router-link>
                              <router-link v-if="!this.isVendor" to="/" class="dropdown-item">
                                  <i class="ni ni-calendar-grid-58"></i>
                                  <span>Activity</span>
                              </router-link>
                              <router-link v-if="!this.isVendor" to="/" class="dropdown-item">
                                  <i class="ni ni-support-16"></i>
                                  <span>Support</span>
                              </router-link>
                              <div   @click="logout" style="cursor:pointer">
                              <div class="dropdown-divider"  @click="logout"></div>
                              <div class="dropdown-item"  @click="logout">
                                  <i class="ni ni-user-run"  @click="logout"></i>
                                  <span  @click="logout">Logout</span>
                              </div>
                              </div>
                          </template>
                      </base-dropdown>
                  </li>
              </ul>
              <div v-if="openModel" id="model">
							<div class="modal" style="display:block; width:100%">
								<div class="modal-dialog" style="width:100%">
									<div class="modal-content t-100" style="max-height:600px;overflow-y:auto; width:100%">
										<div class="modal-header"> 
											<a
												class="pl-2 pt-1 external-link-alt"
												style="display:inline-block"
												target="_blank"
												:href="url"
											>
												<h5 class="modal-title pointer">View Product</h5>
											</a>
											<a
												class="pl-2 external-link-alt"
												style="display:inline-block"
												target="_blank"
												:href="url"
											>
												<i class="fa fa-eye m-0"></i>
											</a>

											<button
												type="button"
												class="close"
												data-dismiss="modal"
												aria-label="Close"
												@click="closeModel()"
											>
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div class="modal-body">
											<div class="table-responsive">
												<table class="table tablesorter" :class="tableClass">
													<thead :class="theadClasses">
														<tr>
															<!-- <th>
                                                            <label class="form-checkbox">
                                                                <input type="checkbox" v-model="selectAll" @click="select">
                                                                <i class="form-icon"></i>
                                                            </label>
															</th>-->
															<th v-for="item in subHeaders" :key="item.id">
																<b>{{item.name}}</b>
															</th>
														</tr>
													</thead>
													<tbody :class="tbodyClasses">
														<tr v-for="(item, index) in subArray" :key="item.id">
															<!-- <td>
                                                            <label class="form-checkbox margin">
                                                                <input type="checkbox" :value="item.id_product" v-model="selected">
                                                                <i class="form-icon"></i>
                                                            </label>
															</td>-->
															<td>
																<span class="margin" style="display:inline-block">{{item.id_product}}</span>
															</td>
															<td class="pl-0 alignment pr-0">
																<img
																	:src="`https://images.wndw.in/live/inside/100x100/${ item.image}`"
																	width="45px"
																	height="auto"
																/>
																<b>{{item.name}}</b>
																<!-- <a class="margin pl-2 external-link-alt" style="display:inline-block" target="_blank" :href=item.url_key><i class="fas fa-external-link-alt m-0"></i></a></td> -->
															</td>
															<td>
																<span class="margin" style="display:inline-block">{{item.sku}}</span>
															</td>
															<td>
																<span class="margin" style="display:inline-block">{{item.size}}</span>
															</td>

															<td>
																<input
																	type="text"
																	v-model="item.price"
																	@input="Quantity(item)"
																	class="m_align input-class mt-10"
																/>
															</td>
															<td>
																<input
																	type="text"
																	@input="Quantity(item)"
																	v-model="item.selling_price"
																	class="m_align input-class mt-10"
																/>
															</td>
															<td>
																<div class="qty_box mt-2">
																	<a @click="Quantity(item,index,0)">
																		<i class="fa fa-minus"></i>
																	</a>
																	<input type="text" v-model="item.quantity" @click="qtyChange(item, index)" />
																	<a @click="Quantity(item,index,1)">
																		<i class="fa fa-plus"></i>
																	</a>
																</div>
															</td>
															<td>
																<span span class="margin" style="display:inline-block">{{item.updated_at}}</span>
															</td>

															<td>
																<base-button
																	type="primary"
																	:disabled="item.isDisable"
																	@click="updateQuantity(item)"
																	size="sm"
																	class="margin"
																>Update</base-button>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
    </base-nav>
</template>
<script>

import axios from "../axiosCustom" 
import * as API from "../API";
import _ from 'lodash';
import ClickOutside from 'vue-click-outside'
//import {messaging} from "../firebase";
// import virtualList from 'vue-virtual-scroll-list'
import { messaging } from '../firebase'
  export default {
    directives: {
      ClickOutside
    },
    data() {
      return {
        subArray: [],
        subHeaders: [
					{ name: "ID", value: "discount" },
					{ name: "Product", value: "discount" },
					{ name: "Sku", value: "discount" },
					{ name: "Size", value: "discount" },
					{ name: "Retail p", value: "discount" },
					{ name: "Selling p", value: "discount" },
					{ name: "Inventory", value: "qty" },
					{ name: "Last Updated" },

					{ name: "Action" }
				],
        isVendor: false,
        openModel: false,
        searchQuery: "",
        value:'all',
        remainder:0,
        isTyping: false,
        searchResult: [],
        isLoading: false,
        activeNotifications: false,
        showMenu: false,
        userName : '',
        searchDrop:false,
        avg_rating:'',
        //data:[],
        items: []
      };
    },
    props: {
			theadClasses: {
				type: String,
				default: "",
				description: "<thead> css classes"
			},
			tbodyClasses: {
				type: String,
				default: "",
				description: "<tbody> css classes"
			},},
    watch: {
      searchQuery: _.debounce(function() {
         this.isTyping = false;
        }, 1000),
        isTyping: function(value) {
          if (!value) {
           this.searchUser(this.searchQuery);
          }
      }
    },

    // components: { 'virtual-list': virtualList },
    beforeMount(){
    },
    async  mounted() {
      if(localStorage.getItem("role") == "vendor"){
        this.isVendor = true;
      }
      if (messaging) {
        navigator.serviceWorker.addEventListener("message", async (m) => {
          console.log(m);
          alert('new order received');
          await this.$store.dispatch("getReqOrder");
          if(window.location.pathname == '/orders/requests' || window.location.pathname == '/dashboard'){
            window.location.reload();
          }
        });
      }
      else {
        alert('Notifications will not work on this browser please change your browser')
      }

      // let token = window.$cookies.get("accessToken")
      // const rating = await axios.get(`/vendor/get-ratings`,{
      //     headers: {
      //     'Authorization': `Bearer ${token}`
      //     }
      //   })
      // this.avg_rating = rating.calculated_ratings.avg_rating|1;
      // this.remainder = rating.calculated_ratings.avg_rating%1
      // if(this.remainder == 0.5){
      //   this.remainder = true
      // }
      // else{
      //   this.remainder = false
      // }
      await this.$store.dispatch('getUser')
      let name = this.$store.state.user.name;
      this.userName = name.charAt(0).toUpperCase()+name.slice(1)
    },
    computed: {
			tableClass() {
				return this.type && `table-${this.type}`;
			}},
   
    methods: {
      async qtyChange(item) {
				this.type = "Direct";
				await this.Quantity(item);
			},
      async Quantity(item, index, type) {
				this.item = item;
				if (type == 0) {
					this.subArray[index].quantity = this.subArray[index].quantity - 1;
				}
				if (type == 1) {
					this.subArray[index].quantity = this.subArray[index].quantity + 1;
				}
				var res = this.subArray.map(e => {
					if (e.id_product == this.item.id_product) {
						e.visibility = "hide";
						return e;
					} else {
						e.visibility = "show";
						return e;
					}
				});
				this.subArray = res;
			},
      async updateQuantity(item) {
				let qty = "";
				qty = item.quantity;
				let index = this.subArray.findIndex(e => e.id_product == item.id_product);
				this.subArray[index].quantity = qty;
				let params = {
					quantity: qty,
					product_id: item.id_product,
					price: item.price,
					selling_price: item.selling_price
				};
				// let resp = await this.$store.dispatch('updateQty',params)
				let token = window.$cookies.get("accessToken");
				try {
					const response = await axios.post(
						`${API.baseURL}/vendor/update-product-quantity`,
						params,
              {
                headers: {
                  Authorization: `Bearer ${token}`
                },
                withCredentials: true
              }
					);
					this.$Msg.success("Product updated successfully", {
						position: "top-right",
						timeout: 3000
					});
          console.log(response);
				} catch (err) {
					this.$Msg.error("Something Went Wrong", {
						position: "top-right",
						timeout: 3000
					});
				}
				//     if(
				//         resp=="The given data was invalid."
				//     ){
				//          this.$Msg.error(resp, {
				//     position: "top-center",
				//     timeout: 3000,
				// });

				//     }else{
				//              this.$Msg.success('Product updated successfully', { position: 'top-right' ,timeout: 3000 });
				//     }

				var res = this.subArray.map(e => {
					e.visibility = 'show';
					return e;
				});
				this.subArray = res;
			},
      open(item) {
        let abcd = [];
        abcd.push(item)
        console.log("itm",abcd);
				this.subArray = abcd;
        console.log("subArray",this.subArray);
				this.url = item.url_key+".html"+"?store="+`${item.store}`+"&id="+`${item.id_product}`;
				// this.subArray.forEach(e=>e.url_key = item.url_key)

				this.openModel = true;
				// document.querySelector("body").style.overflow = "hidden";
				// document.querySelector("#model").style.overflow = "auto";
			},
      hide(){
        this.searchDrop = false;
        this.items = [];
        this.products = [];
        this.searchQuery = '';
      },
      closeModel() {
				this.openModel = false;
				this.subArray = [];
				this.exportModel = false;
			//	document.querySelector("body").style.overflow = "auto";
			},
      enterKey(event){
        if (event.which == '13') {
         event.preventDefault();
        }
      },
      filter(){
        if(this.value=='items'){
            this.products=[];
            this.items = this.res.items
        }
        else if(this.value == 'products'){
          this.items = [];
          this.products = this.res.products
        }
        else{
          this.items = this.res.items;
          this.products = this.res.products
        }
      },
     async  searchUser() {
        this.isLoading = true;
        let token = window.$cookies.get("accessToken")
        await axios.get(`/vendor/search/${this.searchQuery}`,{
          headers: {
          'Authorization': `Bearer ${token}`
          }
        })
        .then((res) => {
           this.res = res;
            this.items = res.items;
            this.products= res.products;
            this.searchDrop = true;
            this.products.map(e=>{
              e.url_key = `https://wndw.in/${e.url_key}`
            })
            this.items.map(e=>{
              e.url_key = `https://wndw.in/${e.url_key}`
            })
            return this.items
        })
        // axios.get('https://api.github.com/search/users?q=' + searchQuery)
        //   .then(response => {
        //     this.isLoading = false;
        //     this.searchResult = response.data.items;
        //   });
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false);
      },
      toggleMenu() {
        this.showMenu = !this.showMenu;
      },
      async logout(){
        // console.log("Logout");
        let token = window.$cookies.get("accessToken")
        await axios.get('/vendor/logout',{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }).then((res)=>{
          if(res){
            localStorage.removeItem("role");
            window.$cookies.remove("accessToken")
             window.location.href = '/login'
          }
        })
      }
      
      
    }
  };
</script>
<style lang="scss" scoped>

 .modal-dialog {
		width: 100% !important;
		text-align: center;
		// margin-left: 10px;
	}
	.alignment {
		padding-top: 12px !important;
	}
	.modal-body {
		padding: 0;
	}
	.p27 {
		padding-top: 27px !important;
	}
	.m_align {
		margin-top: 12px !important;
	}
	.pointer {
		cursor: pointer;
	}
	.form-check.form-group.csv-import-checkbox {
		display: none !important;
	}
	.form-check {
		position: relative;
		display: none !important;
		padding-left: 1.25rem;
	}
	.import {
		left: 155px;
	}
	.import-body {
		padding: 16px;
	}
	.modal-dialog {
		max-width: 80%;
	}
 .card-header-search{
   float: left;
   max-height: 400px;
   padding-left: 0 !important;
   overflow-y: auto;
   max-width: 100%;
   position: absolute;
    top: 49px;
   
    overflow-x: hidden;
 }
 .serchWrap{
   position: relative;
       width: 560px;
 }
 .serchWrap .input-group{
     width: 100%;

 }
.row-class{
  margin-bottom: 4px;
  margin-top: 2px;
  list-style: none;
}
.item-name{
  padding-left: 0px;
}
.container-fluid{
  padding-left: 0 !important;
}
.li .row-class:hover{
  background-color: burlywood !important
}
.navbar-search-dark .form-control {
  color: black;
  width: 100%;
}
.checked {
  color: orange;
}
</style>