<template>
<div>
      <li v-if="link.children.length>0">
        <a data-v-61fadc8f="" class="nav-link drop-link" style="display:block">
        <i :class="link.icon" class="float-left" id="link.id" ></i>
        <button style="width:149px" :class="{'active': parent == true }" class="dropbtns dropdown-btn text-left pl-0 mt-0 float-left" @click="class1(link.name,link.id)">
        <span class="nav-link-text mr-1">{{ link.name }}</span>
        <!-- <i class="fa fa-caret-down float-right ml-5"></i> -->
        </button>
        <div class="drop-link-child " :id='link.name' style="display:none">
        <router-link  :to="{path:data.path ,query:{key: data.query ? data.query : '' }}" v-for="data in link.children"   :key="data.id">{{data.name}}</router-link >
        </div>
        </a>
      </li>
          <!-- <div class="footer">
            <h3 @click="toggle('com')" :class="{ 'active': this.footer_active === 'com'}">{{link.name}}
            </h3>

            <div class="mobile-view" :class="{ 'active':  this.footer_active === 'com'}">{
            <ul v-for="data in link.children">
              {{data.name}}
                <li><a href="https://www.v2kart.com/blog" target="_blank">Blog</a></li> 
               <li><a href="https://www.v2kart.com/login-page" target="_blank">B2B Buyer Login</a></li>
            </ul>
            </div>
          </div> -->
   
    <li class="nav-item" v-else>
          <router-link 
                :to="link.path"
                @click.native="linkClick"
                class="nav-link"
                :target="link.target"
                :href="link.path">
            <template>
                <i :class="link.icon"></i>
                <span class="nav-link-text">{{ link.name }}</span>
            </template>
        </router-link>
    </li>
</div>
</template>
<script>

  export default {
    name: 'sidebar-item',
    props: {
      link: {
        type: Object,
        default: () => {
          return {
            
            name: '',
            path: '',
            data:false,
            children: []
          };
        },
        description:
          'Sidebar link. Can contain name, path, icon and other attributes. See examples for more info'
      }
    },
    inject: {
      autoClose: {
        default: true
      }
    },
    data() {
      return {
        children: [],
        collapsed: true,
        footer_active : 'testt',
        parent:false,
      };
    },
    beforeMount(){


    },

    methods: {
      //  toggle(name){
      //    console.log(name);
      //     if(name != this.footer_active){
      //       console.log("enter",name)
      //         this.footer_active = name;
      //         this.data = true

      //     }
      //     else
      //     {
      //         this.footer_active = '';
      //     }
      // },
      class1(id){
        console.log("come");
        var element = document.getElementById(id);
        // var el = document.getElementById(_id);
        console.log("ele",element)

        element.classList.toggle("showDrop")
        if(this.parent) this.parent = false;
        else this.parent = true;
        console.log(this.parent );
        // if(this.parent)
        // el.classList.add("iconClass")
      },
      linkClick() {
        if (
          this.autoClose &&
          this.$sidebar &&
          this.$sidebar.showSidebar === true
        ) {
          this.$sidebar.displaySidebar(false);
        }
      },
      open(link){
        console.log("link",link)
      },
    }
  };
</script>
<style scoped>
.drop-link-child a{
    width: 100%;
    display: block;
    padding-left: 35px;
    padding-top: 5px;
    padding-bottom: 5px;color:#333;
    
}

.drop-link-child{
    width: 100%;
    padding: 0px;
    margin: 0;
}
.drop-link-child a:hover{ 
    background-color:#e9ecef;
    color:#2dcecc
}
.drop-link-child.showDrop{
  display: block;
}
.showDrop{
  padding: 0;
  display: inline-block !important;
}
.hideDrop{
  display: none;
}
.dropdown-btn {
  padding: 3px 8px 6px 16px;
  text-decoration: none;
  font-size: 0.9rem;
  color: #818181;
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: center;
  cursor: pointer;
  outline: none;
}
.dropdown-btn.active {
 font-weight: bold;
 color: black;
}
.iconClass{
    color:#2dce89
}
.dropdown-container {
  display: none;
  padding-left: 8px;
}
.dropdown{
    display: block;
}
.mobile-view {
    display: none;
}
.mobile-view.active {
    display: block;
    padding-top: 10px;
}
.footer h3 .active {
    content: "\f068";
}
 .footer h3 .active::after {
    content: "\f068";
}
.footer h3::after {
    content: "\f067";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    float: right;
    margin-top: 3px;
}
.dropdown-container.active {
    display: inline-block;
}
.drop-link-child a.active{    
      /* background-color: #e9ecef; */
      color: #2dce89;
}
</style>