// import { BIconArrowReturnLeft } from 'bootstrap-vue'
import Vue from 'vue'
import Vuex from 'vuex'
import axios from "../axiosCustom" 
Vue.use( Vuex )
import Msg from 'v-msgx';
import 'v-msgx/dist/msg.min.css';
Vue.use( Msg );

export const store = new Vuex.Store({
	state: {
        updateval:[],
        orderInvoice: '',
        token:'',
        page:1,
        totalProduct:'',
        totalOrder:'',
        user:{},
        orders:[],
        details:[],
        Catalouge:[],
        Settlement:[],
        reqOrders:[],
        totalSettlement:{},
        returns:[],
        category:[],
        totalReturns:'',
	},
	getters: {
		user (state) {
			return state.user
        },
        products: state => {
            return state.Catalouge
        }
	},
	mutations : {
		setUser: (state, user) => {
      
			state.user = user;
        },
        setOrders: (state, orders) => {
            state.orders = []
            state.orders = orders.data;
            state.totalOrder = orders.total
        },
        setReturns:(state,Returs)=>{
            console.log("setReturns", Returs)
            state.returns = Returs.data.data;
            state.totalReturns = Returs.data.total
        },
        setCatalouge: (state, Catalouge) => {
            state.Catalouge = Catalouge.data;
            state.totalProduct = Catalouge.meta.total
            console.log("state",state)
        },
        setSettlement: (state, data) => {
            state.Settlement = data.data;
            state.totalSettlement = data.total
            state.Settlement.sort((a, b) => b.id - a.id );
            console.log("state",state)
        },
        setCategory:(state, data)=>{
            state.category = data
        },
        setToken: (state, token) => {
			state.token = token;
        },
        setDetails: (state, details)=> {
            state.details = details
        },
        setUpdatePrice: (state, checkPrice)=> {
            state.updateval = checkPrice
        },
        setReqOrders: (state, reqOrders)=> {
            console.log("reqOrdersreqOrdersreqOrders",reqOrders)
            state.reqOrders = reqOrders
        },
        setOrderInvoice: (state, data)=> {
           
            state.orderInvoice = data
        }
	},
    actions :{
       async updateOrderInvoice ({commit}, payload) {
            let token = window.$cookies.get("accessToken")
            
            let res =  await axios.post('/vendor/update-invoice-number', payload,{
                    headers: {
                    'Authorization': `Bearer ${token}`
                    }
                    }).then( (response) => {
                      
                        if(response.status === 'success'){
                            commit('setOrderInvoice', payload.invoice_number)
                            return response
                           
                            // console.log(response)
                        }
                        else{
                            return response
                        }
                    })
                    .catch(function (error) {
                        if(error.message === 'Unauthorized') {
                            window.$cookies.remove("accessToken")
                            window.location.href = '/login'
                        }
                        // console.log("eer", error)
                        return error;
                    })
            return res


        },
        async login ({commit}, params) {
        alert("enter")
        await axios.post('/vendor-login', params)
        .then(function (response) {
            console.log("res",response)
            if(response.message === 'logged in successfully'){
                commit('setToken', response.accessToken);
                return response;
            }
            else{
                return response
            }
        })
        .catch(function (error) {
            if(error.message === 'Unauthorized') {
                window.$cookies.remove("accessToken")
                window.location.href = '/login'
            }
            console.log("eer", error)
            return error;
        })
    },
    
		async getCatalouge ({commit},params){
            let token = window.$cookies.get("accessToken")
            await axios.get(`/vendor/get-catalouge?page=${params}`,{
                headers: {
                'Authorization': `Bearer ${token}`
                }
            })
            .then((res) => {
                console.log("res11111111",res)
                commit('setCatalouge', res);
                console.log("111qqq",res.data)
                return res.data.data

            })
            .catch((error) => {
                if(error.message === 'Unauthorized') {
                    window.$cookies.remove("accessToken")
                    window.location.href = '/login'
                }
                console.error(error)
            })

        },	
        
        
        async getSettlement ({commit},params){
            let token = window.$cookies.get("accessToken")
            console.log("token",token);
            await axios.get(`/vendor/get-transactions?page=${params}`,{
                headers: {
                'Authorization': `Bearer ${token}`
                }
            })
            .then((res) => {
                console.log("res in settlement",res)
                commit('setSettlement', res.data);
                console.log("111qqq",res.data)
                return res.data.data

            })
            .catch((error) => {
                if(error.message === 'Unauthorized') {
                    window.$cookies.remove("accessToken")
                    window.location.href = '/login'
                }
                console.error(error)
            })

        },	

        async updatePrice({commit}){
            let token = window.$cookies.get("accessToken")

            await axios.post('/vendor/show-price-update-options',[],{
                headers: {
                'Authorization': `Bearer ${token}`
                }
            })
            .then((res) => {
                commit('setUpdatePrice', res.data);
                return res.data
            })
            .catch((error) => {
                if(error.message === 'Unauthorized') {
                    window.$cookies.remove("accessToken")
                    window.location.href = '/login'
                }
                console.error(error)
            })
        },
        async getOrderDetails ({commit},params){
   console.log("params",params)
            let token = window.$cookies.get("accessToken")
            
            if(params.h == true || params.h == 'true'){
                params = `${params.id}/true`
            }
            else if(params.type=='pending_order'){
                params = `${params.id}?o=true&pending_order=true`
            }
            else{
                params = `${params}?o=true`
            }
            try {
                let res = await axios.get(`/vendor/get-store-order/${params}`,{
                    headers: {
                    'Authorization': `Bearer ${token}`
                    }
                })
                if(res){
                    commit('setDetails', res.data);
                    return res.data
                
                }
            } catch (error) {
                if(error.message === 'Unauthorized') {
                    window.$cookies.remove("accessToken")
                    window.location.href = '/login'
                }
                console.error(error)
            }
        },

        async updateQty ({commit},params){
            let token = window.$cookies.get("accessToken")
            console.log("token-index", token);
            await axios.post('/vendor/update-product-quantity',params,{
                headers: {
                'Authorization': `Bearer ${token}`
                }
            })
            .then((res) => {
                
                commit('')
                // commit('setCatalouge', res.data.data, res.data.total);
                return res.message

            })
            .catch((error) => {
                if(error.message === 'Unauthorized') {
                    window.$cookies.remove("accessToken")
                    window.location.href = '/login'
                }
        commit('')
                return error
                
            })

        },	
        
        async getUser({commit}){
           
            let token = window.$cookies.get("accessToken")
            await axios.get('/vendor/get-store-details',{
                headers: {
                'Authorization': `Bearer ${token}`
                }
            })
            .then((res) => {
                
                commit('setUser', res.data);
                return res.data ;

            })
            .catch((error) => {
                if(error.message === 'Unauthorized') {
                    window.$cookies.remove("accessToken")
                    window.location.href = '/login'
                }
                console.error(error)
            })

        },
        async getCategory({commit}){
            console.log('1111111111111111r')
            let token = window.$cookies.get("accessToken")
            await axios.get('/vendor/get-store-categories',{
                headers: {
                'Authorization': `Bearer ${token}`
                }
            })
            .then((res) => {
                commit('setCategory', res);
                console.log("res111111111111111111",res)
                return res ;

            })
            .catch((error) => {
                if(error.message === 'Unauthorized') {
                    window.$cookies.remove("accessToken")
                    window.location.href = '/login'
                }
                console.error(error)
            })

        },
        async updateStore({commit},params){
            let token = window.$cookies.get("accessToken")
            await axios.post('/vendor/store-edit-info',params,{
                headers: {
                'Authorization': `Bearer ${token}`
                }
            })
            .then((res) => {
                console.log("res",res)
                commit('setUser', res.data);
                return res ;

            })
            .catch((error) => {
                if(error.message === 'Unauthorized') {
                    window.$cookies.remove("accessToken")
                    window.location.href = '/login'
                }
                console.error(error)
            })

        },
      
        async getOrders ({commit},params){
          
            let token = window.$cookies.get("accessToken")
            await axios.get(`/vendor/get-store-orders?page=${params.item}&key=${params.key}`,{
                headers: {
                'Authorization': `Bearer ${token}`
                }
            })
            .then((res) => {
                console.log("res.data.total",res.data.total)
                commit('setOrders', res.data);
                store.totalOrder=res.data.total
                console.log("orders",res.data)
                return res.data

            })
            .catch((error) => {
                if(error.message === 'Unauthorized') {
                    window.$cookies.remove("accessToken")
                    window.location.href = '/login'
                }
            })
        },

        async getReturns ({commit},params){
            let token = window.$cookies.get("accessToken")
            await axios.get(`/vendor/get-return-exchange-items?page=${params.item}&key=${params.key}`,{
                headers: {
                'Authorization': `Bearer ${token}`
                }
            })
            .then((res) => {
               
                commit('setReturns', res);
                store.totalReturns=res.data.total
                return res.data

            })
            .catch((error) => {
                if(error.message === 'Unauthorized') {
                    window.$cookies.remove("accessToken")
                    window.location.href = '/login'
                }
                console.error(error)
            })
        },
        // async orderAction({commit},params){
        //     let token = window.$cookies.get("accessToken")
        //     await axios.get(`/vendor/get-return-exchange-items?page=${params}`,{
        //         headers: {
        //         'Authorization': `Bearer ${token}`
        //         }
        //     })
        //     .then((res) => {
        //         console.log("res.data.total",res.data)
        //         commit('setReturns', res);
        //         store.totalReturns=res.data.total
        //         console.log("orders",res.data)
        //         return res.data

        //     })
        //     .catch((error) => {
        //         console.error(error)
        //     })
        // },

        async getReqOrder ({commit}){
            let token = window.$cookies.get("accessToken")
            await axios.get(`/vendor/get-new-orders`,{
                headers: {
                'Authorization': `Bearer ${token}`
                }
            })
            .then((res) => {
                console.log("res.data.totalddddddddddddddddddddddd",res.data)
                commit('setReqOrders', res.data);
                return res.data

            })
            .catch((error) => {
                if(error.message === 'Unauthorized') {
                    window.$cookies.remove("accessToken")
                    window.location.href = '/login'
                }
                console.error(error)
            })
        },
		async acceptOrder ({commit},params){
            console.log("acceptOrder",params)
            let token = window.$cookies.get( "accessToken" )
            if ( params.is_hopped == true )
            {
                console.log(params)
               
                let response = await axios
									.post("/vendor/accept-reject-hopped-order", params, {
										headers: {
											Authorization: `Bearer ${token}`,
										},
									})
									.then((res) => {
										console.log("11ss1", res);
										let data = [];
										commit("setOrders", data);

										// commit('setCatalouge', res.data.data, res.data.total);
										return res;
									})
									.catch((error) => {
										if (error.message === "Unauthorized") {
											window.$cookies.remove("accessToken");
											window.location.href = "/login";
										}
										console.error(error);
                                    } );
                return response;
            }
            else
            {
                let response = await axios
									.post("/vendor/accept-reject-order", params, {
										headers: {
											Authorization: `Bearer ${token}`,
										},
									})
									.then((res) => {
										console.log("11ss1", res);
										let data = [];
										commit("setOrders", data);

										// commit('setCatalouge', res.data.data, res.data.total);
										return res;
									})
									.catch((error) => {
										if (error.message === "Unauthorized") {
											window.$cookies.remove("accessToken");
											window.location.href = "/login";
										}
										
                                       return error;
									});
                                   
								return response;
            }
            
            

        },
	}
})

