
let host=location.host
var baseURL='';
if(host=='vendor.wndw.in'){
     baseURL = "https://admin.wndw.in/api";
     console.log = function() {}
}
else{
  baseURL = "https://lhmadmin.greenhonchos.com/api";
}

export {baseURL};
