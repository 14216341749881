import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'
Vue.use(Router)

export default new Router({
	linkExactActiveClass: "active",
	mode: "history",

	routes: [
		{
			path: "/",
			redirect: "dashboard",
			component: DashboardLayout,

			children: [
				{
					path: "/dashboard",
					name: "dashboard",
					// route level code-splitting
					// this generates a separate chunk (about.[hash].js) for this route
					// which is lazy-loaded when the route is visited.
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/Dashboard.vue"),
				},
				{
					path: "/icons",
					name: "icons",
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/Icons.vue"),
				},
				{
					path: "/profile",
					name: "profile",
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/UserProfile.vue"),
				},
				{
					path: "/maps",
					name: "maps",
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/Maps.vue"),
				},
				{
					path: "/tables",
					name: "tables",
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/Tables.vue"),
				},
				{
					path: "/orders/all",
					name: "Orders",
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/Orders.vue"),
				},
				{
					path: "/orders/requests",
					name: "Requests",
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/Request.vue"),
				},
				{
					path: "/invoice",
					name: "Invoice",
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/Invoice.vue"),
				},

				{
					path: "/themes",
					name: "Themes",
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/Thems.vue"),
				},
				{
					path: "/slip",
					name: "Slip",
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/Slip.vue"),
				},
				{
					path: "/orders/pick-ups",
					name: "Pick-ups",
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/Pickups.vue"),
				},
				{
					path: "/inventory-update",
					name: "Inventory Update",
					component: () =>
						import(
							/* webpackChunkName: "demo" */ "./views/InventoryUpload.vue"
						),
				},
				{
					path: "/products",
					name: "products",
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/Catalouge.vue"),
				},
				{
					path: "/plans",
					name: "plans",
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/Plans.vue"),
				},
				{
					path: "/addnew",
					name: "addnew",
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/Add.vue"),
				},
				{
					path: "/addnew-product",
					name: "addnewproduct",
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/AddProduct.vue"),
				},
				{
					path: "/settings",
					name: "settings",
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/Settings.vue"),
				},
				{
					path: "/settlements",
					name: "settlements",
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/Settlements.vue"),
				},
				{
					path: "/store",
					name: "Store Closure",
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/storeClosure.vue"),
				},
				{
					path: "/returns",
					name: "Returns",
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/Returns.vue"),
				},
				{
					path: "/discounts",
					name: "discounts",
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/Discounts.vue"),
				},
				{
					path: "/customer",
					name: "customer",
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/Customer.vue"),
				},
				{
					path: "/banners",
					name: "Banners",
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/Store.vue"),
				},
				{
					path: "/add-banner",
					name: "Add Banner",
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/addBanner.vue"),
				},
				{
					path: "/media",
					name: "Media",
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/Media.vue"),
				},
				{
					path: "/about",
					name: "About",
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/About.vue"),
				},
				{
					path: "/details",
					name: "Details",
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/Details.vue"),
				},
				{
					path: "/reviews",
					name: "Reviews",
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/Reviews.vue"),
				},
				{
					path: "/return-details",
					name: "Return Details",
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/Return-items.vue"),
				},

				{
					path: "/order-details",
					name: "Order Details",
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/order-status.vue"),
				},
				{
					path: "/settlement-details",
					name: "Settlement Details",
					component: () =>
						import(
							/* webpackChunkName: "demo" */ "./views/settlement-details.vue"
						),
				},
				{
					path: "/changePassword",
					name: "ChangePassword",
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/Password.vue"),
				},
				{
					path: "/fullfillment",
					name: "Fullfillment",
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/Fullfillment.vue"),
				},
				//Reports Routes starts

				{
					path: "/ProductReview",
					name: "ProductReview",
					component: () =>
						import(
							/* webpackChunkName: "demo" */ "./views/Reports/ProductReview.vue"
						),
				},
				{
					path: "/SaleOrder",
					name: "SaleOrder",
					component: () =>
						import(
							/* webpackChunkName: "demo" */ "./views/Reports/SaleOrder.vue"
						),
				},
				{
					path: "/ReturnOrder",
					name: "ReturnOrder",
					component: () =>
						import(
							/* webpackChunkName: "demo" */ "./views/Reports/ReturnOrder.vue"
						),
				},
				{
					path: "/Inventory",
					name: "Inventory",
					component: () =>
						import(
							/* webpackChunkName: "demo" */ "./views/Reports/Inventory.vue"
						),
				},
				{
					path: "/SellerReview",
					name: "SellerReview",
					component: () =>
						import(
							/* webpackChunkName: "demo" */ "./views/Reports/SallerReview.vue"
						),
				},

				//Reports route end
			],
		},
		{
			path: "/",
			redirect: "login",
			component: AuthLayout,
			children: [
				{
					path: "/login",
					name: "login",
					// meta: {
					//   requiresAuth: fas,
					// },
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/Login.vue"),
				},
				{
					path: "/register",
					name: "register",
					component: () =>
						import(/* webpackChunkName: "demo" */ "./views/Register.vue"),
				},
			],
		},
	],
});
